<template>
    <div class="footer">
        <div class="d-none d-sm-flex justify-space-between align-center">
            <div>
                <div class="d-flex justify-start align-center">
                    <div>
                        <v-img width="38" contain src="@/assets/images/logo.png"></v-img>
                    </div>
                    <div class="text-h5 white--text font-weight-bold ml-2">SuperCells</div>
                </div>
                <div class="white--text text-caption white--text mt-5" style="max-width:700px;">
                    {{ $t('Home.DescriptionSim') }}
                </div>
                <div class="d-flex justify-start align-center text-body-2 mt-7">
                    <v-icon color="#AC8250" size="20" class="mr-1">mdi-file-document-outline</v-icon>
                    <a v-if="newsil8n == 'ja'" target="_blank" href="/SuperCells3.0—JP.pdf" class="email">{{
                        $t('Footer.WhitePaper') }}</a>
                    <a v-else-if="newsil8n == 'ko'" target="_blank" href="/SuperCells3.0—KR.pdf" class="email">{{
                        $t('Footer.WhitePaper') }}</a>
                    <a v-else target="_blank" href="/SuperCells3.0—EN.pdf" class="email">{{ $t('Footer.WhitePaper') }}</a>
                </div>
                <div class="d-flex justify-start align-center text-body-2 mt-4">
                    <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                    <a href="mailto:business@supercells.vip" class="email">{{ $t('Footer.Commerce') }}
                        business@supercells.vip</a>
                </div>
                <div class="d-flex justify-start align-center text-body-2 mt-3">
                    <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                    <a href="mailto:service@supercells.vip" class="email">{{ $t('Footer.Support') }}
                        service@supercells.vip</a>
                </div>
            </div>
            <div class="d-flex justify-end">
                <a href="https://twitter.com/super_cells" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                </a>
                <a href="https://discord.com/invite/XaSfW7Je2W" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/discord_a.svg"></v-img>
                </a>
                <a href="https://t.me/SUPERCELLS_GLOBAL" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/telegram_a.svg"></v-img>
                </a>
                <a href="https://www.youtube.com/@SuperCellsOFFICAL/channels" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/youtube.png"></v-img>
                </a>
            </div>
        </div>

        <div class="d-block d-sm-none">
            <div class="d-flex justify-space-between">
                <div class="d-flex justify-start align-center">
                    <div>
                        <v-img width="30" contain src="@/assets/images/logo.png"></v-img>
                    </div>
                    <div class="text-body-1 font-weight-bold ml-2">SuperCells</div>
                </div>
                <div class="d-flex justify-end">
                    <a href="https://twitter.com/super_cells" target="_blank" class="d-block">
                        <v-img contain width="28" src="@/assets/images/twitter_a.svg"></v-img>
                    </a>
                    <a href="https://discord.com/invite/XaSfW7Je2W" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/discord_a.svg"></v-img>
                    </a>
                    <a href="https://t.me/SUPERCELLS_GLOBAL" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/telegram_a.svg"></v-img>
                    </a>
                    <a href="https://www.youtube.com/@SuperCellsOFFICAL/channels" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/youtube.png"></v-img>
                    </a>
                </div>
            </div>
            <div class="white--text text-body-1 mt-6">
                {{ $t('Home.DescriptionSim') }}
            </div>
            <div class="d-flex justify-start align-center text-body-2 mt-7">
                <v-icon color="#AC8250" size="20" class="mr-1">mdi-file-document-outline</v-icon>
                <a v-if="newsil8n == 'ja'" target="_blank" href="/SuperCells3.0—JP.pdf" class="email">{{
                    $t('Footer.WhitePaper') }}</a>
                <a v-else target="_blank" href="/SuperCells3.0—EN.pdf" class="email">{{ $t('Footer.WhitePaper') }}</a>
            </div>
            <div class="d-flex justify-start align-center text-body-2 mt-7">
                <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                <a href="mailto:business@supercells.vip" class="email">{{ $t('Footer.Commerce') }}
                    business@supercells.vip</a>
            </div>
            <div class="d-flex justify-start align-center text-body-2 mt-3">
                <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                <a href="mailto:service@supercells.vip" class="email">{{ $t('Footer.Support') }} service@supercells.vip</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        newsil8n: null,
    }),
    mounted() {
        this.newsil8n = this.$i18n.locale
    },
    watch: {
        '$i18n.locale'(newil8n) {
            this.newsil8n = newil8n;
        }
    },
}
</script>

<style lang="scss">
.footer {
    padding: 48px 40px 70px;
    background: #221816;
    color: #AC8250;
    overflow-x: auto;

    .logo-block {
        width: 39px;
        height: 39px;
        background: #AC8250;
        border-radius: 50%;
    }

    a.email {
        color: #AC8250;
    }
}


@media screen and (max-width: 960px) {
    .footer {
        padding: 30px 20px 54px;
        background: #221816;

        .logo-block {
            width: 30px;
            height: 30px;
        }
    }
}
</style>
