<template>
    <div>
        <div class="road">
          <div class="text-h5 font-weight-bold mb-5">{{ $t('Page.Road.Title') }}</div>
          <v-timeline>
            <v-timeline-item small color="#AC8250" v-for="(item, i) in roads" :key="i">
              <div :class="i%2 == 1 ? 'text-right' : 'text-left'">
                <div class="font-weight-bold mb-2" style="color:#AC8250">{{$t(item.date)}}</div>
                <div v-html="$t(item.text)"></div>
                <div v-html="$t(item.text1)"></div>
                <div v-html="$t(item.text2)"></div>
                <div v-html="$t(item.text3)"></div>
              </div>
            </v-timeline-item>
          </v-timeline>

          <div class="text-h6 grep--text text-center  pt-10 mb-10">{{ $t('Page.Raad.Incoming') }}</div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'

export default {
    components: {
        Footer
    },
    data: () => ({
      roads: [
      {
        "date": "Page.Road.Quarter2021",
        "text": "Page.Road.Quarter2021Text",
      },
      {
        "date": "Page.Road.Quarter202201",
        "text": "Page.Road.Quarter202201Text",
      },
      {
        "date": "Page.Road.Quarter202202",
        "text": "Page.Road.Quarter202202Text",
      },
      {
        "date": "Page.Road.Quarter202203",
        "text": "Page.Road.Quarter202203Text",
      },
      {
        "date": "Page.Road.Quarter202204",
        "text": "Page.Road.Quarter202204Text",
      },
      {
        "date": "Page.Road.Quarter202301",
        "text": "Page.Road.Quarter202301Text",
      },
      {
        "date": "Page.Road.Quarter202302",
        "text": "Page.Road.Quarter202302Text",
      },
      {
        "date": "Page.Road.Quarter202303",
        "text": "Page.Road.Quarter202303Text",
      },
      {
        "date": "Page.Road.Quarter202304",
        "text": "Page.Road.Quarter202304Text",
      },
      // {
      //   "date": "Page.Road.Day20225",
      //   "text": "Page.Road.Txt20225"
      // },
      // {
      //   "date": "Page.Road.Day20226",
      //   "text": "Page.Road.Txt20226"
      // },
      // {
      //   "date": "Page.Road.Day20227",
      //   "text": "Page.Road.Txt20227"
      // },
      // {
      //   "date": "Page.Road.Day20228",
      //   "text": "Page.Road.Txt20228"
      // },
      // {
      //   "date": "Page.Road.Day20229",
      //   "text": "Page.Road.Txt20229"
      // },
      // {
      //   "date": "Page.Road.Day202210",
      //   "text": "Page.Road.Txt202210"
      // },
      // {
      //   "date": "Page.Road.Day202211",
      //   "text": "Page.Road.Txt2022111"
      // },
      // {
      //   "date": "Page.Road.Day202211",
      //   "text": "Page.Road.Txt2022112"
      // },
      // {
      //   "date": "Page.Road.Day20231",
      //   "text": "Page.Road.Txt202310"
      // },
      // {
      //   "date": "Page.Road.Day20231",
      //   "text": "Page.Road.Txt202312"
      // },
      // {
      //   "date": "Page.Road.Day20231",
      //   "text": "Page.Road.Txt202313"
      // },
      // {
      //   "date": "Page.Road.Day20232",
      //   "text": "Page.Road.Txt202321"
      // },
      // {
      //   "date": "Page.Road.Day20232",
      //   "text": "Page.Road.Txt202322"
      // },
      // {
      //   "date": "Page.Road.Day20232",
      //   "text": "Page.Road.Txt202323"
      // },
      // {
      //   "date": "Page.Road.Day20232",
      //   "text": "Page.Road.Txt202324"
      // },
      // {
      //   "date": "Page.Road.Day20233",
      //   "text": "Page.Road.Txt202331"
      // },
      // {
      //   "date": "Page.Road.Day20233",
      //   "text": "Page.Road.Txt202332"
      // },
      // {
      //   "date": "Page.Road.Day20233",
      //   "text": "Page.Road.Txt202333"
      // },
      // {
      //   "date": "Page.Road.Day20234",
      //   "text": "Page.Road.Txt20234"
      // },
      // {
      //   "date": "Page.Road.Day20235",
      //   "text": "Page.Road.Txt20235"
      // },
      // {
      //   "date": "Page.Road.Day20236",
      //   "text": "Page.Road.Txt20236"
      // },
      // {
      //   "date": "Page.Road.Day202311",
      //   "text": "Page.Road.Txt202311"
      // }
    ]
    })
}
</script>

<style>
.road {
    min-height: calc(100vh - 289px);
    padding: 50px 120px 80px;
    background: #FCF9F6;
}

@media screen and (max-width: 960px) {
    .road {
      padding: 30px 24px;
    }
  }
</style>
